// @ts-nocheck
import Service, { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { apiBaseURL, cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
// @ts-expect-error -- declaration not available for ErrorInfo
import { ErrorInfo } from 'qonto/utils/error-info';

export const FIRST_TIME_EXPERIENCE_STATES = {
  NOT_STARTED: 'not_started',
  INTRO_COMPLETED: 'intro_completed',
  FORECAST_SET: 'forecast_set',
} as const;

type FirstTimeExperienceState =
  (typeof FIRST_TIME_EXPERIENCE_STATES)[keyof typeof FIRST_TIME_EXPERIENCE_STATES];

interface GetFirstTimeExperienceStateArgs {
  forceRefresh: boolean;
}

export default class CashFlowManager extends Service {
  @service networkManager;
  @service organizationManager;
  @service sentry;

  @reads('organizationManager.membership.id') membershipId;

  @tracked firstTimeExperienceState: FirstTimeExperienceState | null = null;

  @waitFor
  async getFirstTimeExperienceState(
    opts: GetFirstTimeExperienceStateArgs
  ): Promise<FirstTimeExperienceState | null> {
    if (!variation('feature--boolean-cash-flow-first-time-experience')) {
      return this.firstTimeExperienceState;
    }
    const { forceRefresh }: GetFirstTimeExperienceStateArgs = { forceRefresh: false, ...opts };

    if (!forceRefresh && this.firstTimeExperienceState) {
      return this.firstTimeExperienceState;
    }

    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${cashFlowCategoriesNamespace}/cash-flow/users/${this.membershipId}/state`
      );

      this.firstTimeExperienceState = response.forecast.first_time_experience_state;
    } catch (error) {
      // Silently ignore the error
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
    return this.firstTimeExperienceState;
  }

  @waitFor
  async updateFirstTimeExperienceState(body): Promise<FirstTimeExperienceState | null> {
    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${cashFlowCategoriesNamespace}/cash-flow/users/${this.membershipId}/state`,
        { method: 'PATCH', body: JSON.stringify(body) }
      );

      this.firstTimeExperienceState = response.forecast.first_time_experience_state;
    } catch (error) {
      // Silently ignore the error
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      throw error;
    }
    return this.firstTimeExperienceState;
  }
}

declare module '@ember/service' {
  interface Registry {
    'cash-flow-manager': CashFlowManager;
  }
}
