import { type Registry as Services } from '@ember/service';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { type ComponentType } from 'react';

import { Addons } from 'qonto/constants/addons';
// eslint-disable-next-line @qonto/no-import-roles-constants
import { ROLES } from 'qonto/constants/membership';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import type MembershipModel from 'qonto/models/membership';
import type Organization from 'qonto/models/organization';
import { ESignaturePromotionalPopup } from 'qonto/react/components/account-receivable/e-signature-promotional-popup';
import {
  CASH_FLOW_FIRST_TIME_EXPERIENCE_STORAGE_KEY,
  FirstTimeExperiencePopup,
} from 'qonto/react/components/cash-flow/components/first-time-experience/popup';
import {
  ESM_POPUP_STORAGE_KEY,
  EsmAddonFeaturesPopup,
} from 'qonto/react/components/esm-addon-features-popup/esm-addon-features-popup';
import { KycPendingPopup } from 'qonto/react/components/kyc/kyc-pending-popup';
import { RibaPromotionalPopup } from 'qonto/react/components/riba-promotional-popup';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';

interface PopupResult {
  reactComponent: ComponentType<any>;
  props?: Record<string, any>;
}

interface PopupDependencies {
  organization: Organization;
  membership: MembershipModel;
  abilities: Services['abilities'];
  subscriptionManager: Services['subscriptionManager'];
  cashFlowManager: any;
}

export function getActivePopup({
  organization,
  membership,
  abilities,
  subscriptionManager,
  cashFlowManager,
}: PopupDependencies): PopupResult | null {
  function hasRibaAbility(): boolean {
    return abilities.can('update riba');
  }

  function shouldUserSeeEsmPopup(): boolean {
    if (safeLocalStorage.getItem(ESM_POPUP_STORAGE_KEY)) {
      return false;
    }
    return [ROLES.ADMIN, ROLES.OWNER].includes(membership.role);
  }

  function getEsmAudience(): string | false {
    let audience = variation('feature--string-spend-management-addon-modal');
    if (!audience || audience === 'off') {
      return false;
    }
    return audience;
  }

  // Main logic
  if (
    membership.shouldSubmitKyc &&
    organization.kybPending &&
    variation('feature--boolean-improve-modal-to-increase-kyc-rate')
  ) {
    return {
      reactComponent: KycPendingPopup,
    };
  }

  if (
    variation('feature--boolean-cash-flow-first-time-experience') &&
    cashFlowManager.firstTimeExperienceState === FIRST_TIME_EXPERIENCE_STATES.NOT_STARTED &&
    safeLocalStorage.getItem(CASH_FLOW_FIRST_TIME_EXPERIENCE_STORAGE_KEY) !== 'true' &&
    abilities.cannot('fully interact with forecast cash-flow')
  ) {
    return {
      reactComponent: FirstTimeExperiencePopup,
    };
  }

  const audience = getEsmAudience();
  if (audience && shouldUserSeeEsmPopup()) {
    return {
      reactComponent: EsmAddonFeaturesPopup,
      props: {
        audience,
      },
    };
  }

  if (
    !organization.underRegistration &&
    variation('feature--boolean-riba-promotional-elements') &&
    hasRibaAbility()
  ) {
    return {
      reactComponent: RibaPromotionalPopup,
    };
  }

  if (
    abilities.can('create receivableInvoice') &&
    subscriptionManager.hasAddon(Addons.AccountsReceivable) &&
    variation('feature--boolean-ar-quotes-esignature')
  ) {
    return {
      reactComponent: ESignaturePromotionalPopup,
    };
  }

  return null;
}
