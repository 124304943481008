import stringHash from './string-hash';

const COLORS = [
  'var(--plum100)',
  'var(--mint100)',
  'var(--peach100)',
  'var(--mustard100)',
  'var(--blue100)',
  'var(--green100)',
  'var(--pink100)',
  'var(--yellow100)',
];

/**
 * Returns the appropriate category color based on a given value.
 *
 * Internally, this function uses modulo to ensure that the index
 * is within the bounds of the COLORS array.
 *
 * @param {string} value - The input value used to determine the color.
 * @returns {string} The selected color from the COLORS array.
 */
export default function colorSelector(value: string | undefined): string {
  const index = stringHash(value) % COLORS.length;

  return COLORS[index] as string;
}
