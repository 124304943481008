/* eslint-disable @qonto/no-import-roles-constants */
// @ts-nocheck
import { ForbiddenError } from '@ember-data/adapter/error';
import { get } from '@ember/object';
import Service, { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import * as Sentry from '@sentry/ember';
import { all, dropTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import {
  companyCreationJsURL,
  registerBaseURL,
  registerJsURL,
  registerNamespace,
  registerPartnersJsURL,
} from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import type MembershipModel from 'qonto/models/membership';
import type Organization from 'qonto/models/organization';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class OrganizationManager extends Service {
  @service featuresManager;
  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service notifierManager;
  @service router;
  @service declare sessionManager: Services['sessionManager'];
  @service store;
  @service userManager;
  @service sentry;
  @service launchdarkly;
  @service zendeskWidget;
  @service zendeskLocalization;
  @service abilities;
  @service categoriesManager;
  @service tandem;

  @tracked isDataLoaded = false;
  @tracked organization: Organization = null;
  @tracked membership: MembershipModel = null;
  @tracked registeringOrganizations = null;
  @tracked companyCreationOrganizations = null;

  constructor() {
    super(...arguments);

    this.notifierManager.on(
      'organization.contract_signed_registered',
      this,
      'loadOrganizationAndMemberships'
    );
    this.notifierManager.on(
      'organization.contract_signed_unregistered',
      this,
      'loadOrganizationAndMemberships'
    );
  }

  willDestroy() {
    this.notifierManager.off(
      'organization.contract_signed_registered',
      this,
      'loadOrganizationAndMemberships'
    );
    this.notifierManager.off(
      'organization.contract_signed_unregistered',
      this,
      'loadOrganizationAndMemberships'
    );
  }

  get accounts() {
    return this.organization.bankAccounts || [];
  }

  get accountingOrganizations() {
    return this.organizations.filter(({ membershipRole }) => membershipRole === ROLES.REPORTING);
  }

  get isKycKybAccepted() {
    return this.organization?.kybAccepted && this.membership?.kycAccepted;
  }

  @reads('organization.primaryAccount') currentAccount;

  /**
   * It is called to populate the orgnization-manager
   * with the user's organizations, after the login
   * and after a page refresh.
   *
   * @public
   * @method setupTask
   *
   * @returns {Promise}
   */
  setupTask = dropTask(async ({ checkForPendingInvite = false } = {}) => {
    let [organizations, partnerOrganizations] = await all([
      this.loadOrganizationAndMemberships(),
      this.loadPartnerOrganizations(),
    ]);

    this.organizations = organizations.filter(
      organization => organization.legalCountry === 'FR' || !organization.underCompanyCreation
    );
    this.companyCreationOrganizations = organizations.filter(
      organization => organization.legalCountry !== 'FR' && organization.underCompanyCreation
    );
    this.partnerOrganizations = partnerOrganizations;

    this.isDataLoaded = true;

    if (!this.organizations.length) {
      let pendingInvites = checkForPendingInvite
        ? await this.store.query('invite', { email: this.userManager.currentUser.email })
        : [];

      if (pendingInvites.length > 0) {
        this.toastFlashMessages.toastError(
          this.intl.t('toasts.errors.resume-invitation-from-email')
        );

        await timeout(macroCondition(isTesting()) ? 0 : 1000);

        return this.sessionManager.invalidate();
      } else if (this.partnerOrganizations.length) {
        window.location.replace(registerPartnersJsURL);
      } else if (this.companyCreationOrganizations.length) {
        window.location.replace(companyCreationJsURL);
      } else {
        // We do not have API organizations, partner-organizations or company-creation organizations so we assume that we have at least one registering organization
        window.location.replace(registerJsURL);
      }
    }
  });

  /**
   * This method is a helper used to pick an organization among the organizations
   * the user has an account for.
   *
   * @public
   * @method getDefaultOrganization
   *
   * @returns {Object} | {Null} Default organization
   */
  getDefaultOrganization() {
    if (!this.organizations || !this.organizations.length) {
      return null;
    }

    if (safeLocalStorage.getItem('organizationId')) {
      let lastVisitedOrga = this.organizations.find(
        item => get(item, 'id') === safeLocalStorage.getItem('organizationId')
      );
      if (lastVisitedOrga) {
        return lastVisitedOrga;
      }
    }

    return this.organizations[0];
  }

  /**
   * Retreive an organization by its slug
   *
   * @public
   * @method getOrganizationBySlug
   * @param  {String} slug Slug of the organization
   * @returns {Object} | {Null} Organization
   */
  getOrganizationBySlug(slug) {
    if (!this.organizations || this.organizations.length === 0) {
      this.sentry.captureMessage('getOrganizationBySlug: organizations not loaded');
      return null;
    }

    return this.organizations.find(item => get(item, 'slug') === slug);
  }

  /**
   * Clean up sensitive data from the store when switching organizations
   *
   * @private
   * @method _cleanupSensitiveData
   * @returns {void}
   */
  _cleanupSensitiveData() {
    const sensitiveModels = [
      // Financial Data
      'transaction',
      'transfer',
      'bulk-transfer',
      'multi-transfer',
      'bulk-beneficiary',
      'multi-beneficiary',
      'wallet-to-wallet',
      'mandate',
      'bank-account',
      'savings-account',
      'remunerated-account',
      'beneficiary',
      'card',
      'customer',
      'receivable-invoice',
      'quote',
      'product',
      'credit-note',
      'self-invoice',
      'f24-payment',
      'f24-order',
      'pagopa-payment',
      'riba-payment',
      'direct-debit',
      'direct-debit-hold',
      'direct-debit-collection-activation',
      'financing-income',
      'financing-installment',

      // User and Access Control
      'invite',
      'allowed-email',
      'team',

      // Business Data
      'client',
      'client-hub',
      'stakeholder',

      // Documents and Attachments
      'attachment',
      'file',
      'document-collection-required-document',

      // Settings and Configuration
      'einvoicing-settings',
      'einvoicing-fr-settings',
      'receivable-invoices-settings',
      'reminders-configuration',
      'export-template',
      'search-preset',
    ];

    // Unload all records of sensitive models from the store
    for (const modelName of sensitiveModels) {
      const records = this.store.peekAll(modelName);
      records.forEach(record => {
        this.store.unloadRecord(record);
      });
    }
  }

  /**
   * Choose and set a new organization
   * Make a copy in the localStorage
   *
   * @public
   * @method setCurrentOrganizationAndMembership
   * @param  {Object} organization Organization to set
   * @returns void
   */
  async setCurrentOrganizationAndMembership(organization) {
    if (!this.organization || !this.membership || this.organization.id !== organization.id) {
      // Clean up sensitive data before switching organizations
      this._cleanupSensitiveData();

      let [membership, completeOrganization] = await Promise.all([
        this.store.adapterFor('membership').fetchMembershipMe({ organizationId: organization.id }),
        this.store.findRecord('organization', organization.id), // load complete organization record
      ]);

      this.organization = completeOrganization;
      this.membership = membership;

      if (this.organization) {
        await this.organization.loadBankAccounts();
      }
    }

    await this._onOrganizationChange(
      this.userManager.currentUser.id,
      this.organization,
      this.membership
    );
  }

  findCurrentMembership(organization) {
    return organization
      .get('memberships')
      .find(item => get(item, 'user.id') === this.userManager.currentUser.id);
  }

  /**
   * Load the organization and memberships associated
   *
   * @public
   * @method loadOrganizationAndMemberships
   * @returns {Promise}
   */
  async loadOrganizationAndMemberships() {
    try {
      const organizationId = safeLocalStorage.getItem('organizationId');

      let { organization, membership, organizations } = organizationId
        ? await this.loadOrganizationById(organizationId)
        : await this.loadDefaultOrganization();

      this.organization = organization;
      this.membership = membership;

      if (this.organization) {
        await this.organization.loadBankAccounts();
      }

      return organizations;
    } catch (error) {
      safeLocalStorage.removeItem('organizationId');

      // try to load the organization and membership again
      if (
        error.status === 404 || // from organizations/:id
        error.status === 422 // from memberships/me when user can't access a given organization
      ) {
        return await this.loadOrganizationAndMemberships();
      }

      if (error instanceof ForbiddenError || error.status === 403) {
        return [];
      }

      throw error;
    }
  }

  async loadOrganizationById(organizationId: string) {
    let [organization, membership, organizations] = await Promise.all([
      this.store.findRecord('organization', organizationId),
      this.store.adapterFor('membership').fetchMembershipMe({ organizationId }),
      this.store.adapterFor('organization').fetchNavigationOrganizations(),
    ]);

    return { organization, membership, organizations };
  }

  async loadDefaultOrganization() {
    let organizations = await this.store.adapterFor('organization').fetchNavigationOrganizations();

    let filteredOrganizations = organizations.filter(
      organization => !organization.underCompanyCreation
    );

    let organization = filteredOrganizations[0];

    if (!organization) {
      return { organization: null, membership: null, organizations };
    }

    let [membership] = await Promise.all([
      this.store.adapterFor('membership').fetchMembershipMe({ organizationId: organization.id }),
      organization.reload(), // load complete organization record
    ]);

    return { organization, membership, organizations };
  }

  async _onOrganizationChange(userId, organization, membership) {
    Sentry.getCurrentScope().setTag('organization_id', organization.id);
    Sentry.getCurrentScope().setTag('organization status', organization.status || null);
    Sentry.getCurrentScope().setTag('user role', membership.role);

    await this._identifyLaunchDarkly(userId, organization, membership);

    this.featuresManager.setup([...organization.features, ...membership.features]);

    safeLocalStorage.setItem('organizationId', organization.id);

    await this.loadCashFlowCategories(organization.id);

    await this.zendeskWidget.selectCountry(organization.legalCountry);
    this.zendeskLocalization.organizationCountry = organization.legalCountry;

    await this.tandem.mount(organization);

    // label lists are not included in the `organizations/:id` response payload
    // as they are included in `v3/organizations`
    // so we need to fetch them separately
    await this.store.query('label-list', {
      organization_id: organization.id,
      includes: ['labels'],
    });
  }

  async _identifyLaunchDarkly(userId, organization, membership) {
    if (this.launchdarkly.isInitialized) {
      await this.launchdarkly.identify({
        userId,
        customData: {
          organization_organizationId: organization.id,
          organization_organizationName: organization.legalName,
          organization_legalCountry: organization.legalCountry,
          organization_contractSignedAt: organization.contractSignedAt?.getTime(),
          organization_legalSector: organization.legalSector || '',
          membership_membershipId: membership.id,
        },
      });
    }
  }

  loadCompanyCreationOrganizations() {
    return this.store.findAll('ccOrganization');
  }

  loadPartnerOrganizations() {
    return this.store.query('partnerOrganization', {
      filter: {
        registration_status_eq: 'pending',
        registration_flow_type_eq: 'seamless',
      },
      include: 'registration',
      fields: {
        registrations: 'status',
        organizations: 'legal_name,registration',
      },
    });
  }

  /**
   * Make a call to find memberships of an organization
   *
   * @public
   * @method findMembers
   *
   * @param  {Object} query Query to pass to the request
   * @param  {Object} filters Filters to pass to the request
   * @returns {Promise}
   */
  async findMembers(query, filters) {
    return await this.store.query('membership', {
      // Update query method to something more accurate
      organization_id: this.get('organization.id'),
      query,
      filters,
      per_page: 200,
    });
  }

  /**
   * Update a membership with onboarded flag to true
   * and make a call to save it
   *
   * @public
   * @method flagMembershipOnboarded
   *
   * @returns {Promise}
   */
  async flagMembershipOnboarded() {
    this.membership.onboardedCard = true;
    await this.get('membership').save();
  }

  /**
   * Load organizations still in registering process
   *
   * @public
   * @method loadRegisteringOrganizations
   *
   * @returns {Promise}
   */
  async loadRegisteringOrganizations() {
    let url = `${registerBaseURL}/${registerNamespace}/organizations`;
    let data = await this.networkManager.request(url);
    this.registeringOrganizations = data.organizations;
  }

  async updateBankAccounts() {
    await this.organization.loadBankAccounts();
  }

  async loadCashFlowCategories(organizationId) {
    if (this.abilities.can('assign category')) {
      await this.categoriesManager.fetchCategoriesTask
        .perform(organizationId)
        .catch(ignoreCancelation);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'organization-manager': OrganizationManager;
    organizationManager: OrganizationManager;
  }
}
