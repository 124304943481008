import { FX_FEE_DISCOUNT_FLAGS } from 'qonto/constants/international-out/fee-discount';
import type Organization from 'qonto/models/organization';
import { PayOut, type Quote } from 'qonto/services/international-out/types';

/**
 * Determines if the SWIFT network is used based on the payment option.
 *
 * @param quote - The transfer quote object.
 * @param quote.payOut - The pay out option.
 * @returns True if the SWIFT network is used (OUR or SHA), false otherwise.
 */
export function isSwiftNetwork(quote: Pick<Quote, 'payOut'>): boolean {
  return isSwiftOur(quote) || isSwiftSha(quote);
}

/**
 * Checks if the payment option is SWIFT OUR.
 * In that case, the sender pays both his and the beneficiary's bank charges.
 *
 * @param quote - The transfer quote object.
 * @param quote.payOut - The pay out option.
 * @returns True if the payment option is SWIFT OUR, false otherwise.
 */
export function isSwiftOur({ payOut }: Pick<Quote, 'payOut'>): boolean {
  return payOut === PayOut.Our;
}

/**
 * Checks if the payment option is SWIFT SHA.
 * In that case, the transfer fees are shared between the sender and the beneficiary of the funds.
 *
 * @param quote - The transfer quote object.
 * @param quote.payOut - The pay out option.
 * @returns True if the payment option is SWIFT SHA, false otherwise.
 */
export function isSwiftSha({ payOut }: Pick<Quote, 'payOut'>): boolean {
  return payOut === PayOut.Sha;
}

type FxDiscountPhase = 'partial' | 'full';

/**
 * Determines if the FX discount feature is enabled for an organization
 *
 * @param {Organization} organization - The organization to check
 * @param {FxDiscountPhase} phase - The rollout phase to check against
 * @returns {boolean} True if the FX discount feature is enabled, false otherwise
 */
export function isFxDiscountEnabled(organization: Organization, phase?: FxDiscountPhase): boolean {
  const isFeatureEnabled = organization.features?.includes(FX_FEE_DISCOUNT_FLAGS.FX_OUT);
  const isFullyRolledOut = organization.features?.includes(FX_FEE_DISCOUNT_FLAGS.FX_IN);

  if (!phase) {
    return isFeatureEnabled;
  }

  return isFeatureEnabled && (phase === 'full' ? isFullyRolledOut : !isFullyRolledOut);
}
